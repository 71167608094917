<template>
  <PrimaryLayout :title="'Billing & Payments'">
    <div class='row'>
      <AvailableBalance :amount="fullBalance" />
    </div>
    <div class='row'>
      <div class='card two-columns'>
        <div class='card-content'>
          <div class='inner-row full-height'>
            <div class='inner-column one-half'>
              <div class='graph-header'>
                <h3 class='sub-title grayed-out'>HoloFuel Burndown</h3>
                <BaseButton class='buy-holofuel' @click="handleBuyHoloFuelClick">Buy HoloFuel</BaseButton>
              </div>
              <MockGraphIcon class='mock-graph' />
            </div>
            <div class='inner-column one-half center-content'>
              <PanelLink class="panel-link" :panelText="'AutoPay Settings'" :disabled="true">
                <CreditCardIcon :disabled="true" />
              </PanelLink>
              <PanelLink class="panel-link" :panelText="'Invoices'"  @click="handlePanelClick">
                <InvoiceIcon />
              </PanelLink>
              <PanelLink class="panel-link" :panelText="'Exceptions'" :disabled="true" :showBadge="true" :badgeText="'2 new'">
                <BangIcon :disabled="true" />
              </PanelLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='card taller two-columns'>
        <div class='card-content'>
          <div class="inner-row card-header">HoloFuel Account</div>
          <div class='inner-row'>
            <div class='hf-account-grid'>
              <div class="hf-account-label">Available Balance</div>
              <div class="hf-account-data">{{fullBalance}} HF</div>
              <div class="hf-account-label">Account Display Name</div>
              <div class="hf-account-data">{{publisherName}}</div>
              <div class="hf-account-label">HoloFuel Address</div>
              <div class="hf-account-data">
                <div class="hf-account-data">This Identicon design and hash ID are both unique representations of this Publisher Portal and associated HoloFuel address. Consider this address like a bank account, or crypto wallet. When you see this identicon, you know it’s your account.</div>
                <div class="identicon-container">
                  <Identicon v-if="agentKey" size="40" :agentKey="agentKey" class="identicon hf-account-data"  />
                  <div class="hf-address"><b>{{agentId}}</b></div>
                </div>
                <div class="hf-account-data"><b>TIP:</b> Clicking on an identicon will copy the associated address for easy transacting in HoloFuel. </div>
                <div class="hf-link-container" @click="handleGoToHoloFuelClick">
                  <ArrowInSquareIcon />
                  <span>Go to HoloFuel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </PrimaryLayout>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useHolofuelStore, useHoloStore, useUiStore } from 'src/stores'
import PrimaryLayout from 'components/PrimaryLayout.vue'
import PanelLink from 'components/PanelLink.vue'
import AvailableBalance from 'components/AvailableBalance.vue'

import MockGraphIcon from '@uicommon/components/icons/MockGraphIcon.vue'
import ArrowInSquareIcon from '@uicommon/components/icons/ArrowInSquareIcon.vue'
import BangIcon from '@uicommon/components/icons/BangIcon.vue'
import CreditCardIcon from '@uicommon/components/icons/CreditCardIcon.vue'
import InvoiceIcon from '@uicommon/components/icons/InvoiceIcon.vue'
import BaseButton from '@uicommon/components/BaseButton.vue'
import Identicon from '@uicommon/components/Identicon.vue'

export default {
  name: 'BillingAndPayments',
  components: {
    PrimaryLayout,
    MockGraphIcon,
    PanelLink,
    ArrowInSquareIcon,
    BangIcon,
    CreditCardIcon,
    InvoiceIcon,
    Identicon,
    BaseButton,
    AvailableBalance
  },
  methods: {
    ...mapActions(useUiStore, ['openGoToHoloFuelModal']),
    handlePanelClick (e) {
      console.log("handlePanelClick", e)
      this.$router.push({ name: 'Billing&PaymentInvoices'})
    },
    handleGoToHoloFuelClick() {
      if( this.dontDisplayConfirmLinkToHoloFuelMessageAgain === false) {
        this.openGoToHoloFuelModal()
      }
      else {
        const tabName = `${this.$t('$.app_name')}-hf`
        window.open(this.publisherHoloFuelUrl, tabName).focus();
      }
    },
    handleBuyHoloFuelClick() {
      window.open(this.springboardUrl)
    }
  },
  computed: {
    ...mapState(useHolofuelStore, { publisherName: 'nickname' } ),
    ...mapState(useHolofuelStore, ['fullBalance'] ),
    ...mapState(useHoloStore, ['agentKey', 'agentId']),
    ...mapState(useUiStore, ['dontDisplayConfirmLinkToHoloFuelMessageAgain', 'publisherHoloFuelUrl', 'springboardUrl']),
  }
}

</script>

<style scoped>

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}
.card {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  flex-basis: 33%;
  background-color: white;
  box-shadow: 0px 4px 20px #ECEEF1;
  border-radius: 12px;
  height: 275px;
}

.card.taller {
  height: 400px;
}
.card:last-child {
  margin-right: 0;
}
.two-columns {
  flex-basis: 100%;
}

.card-content {
  padding: 22px 28px;
  height: 100%;
}

.inner-row {
  display: flex;
  flex-direction: row;
}
.inner-row.full-height {
  height: 100%;
}
.inner-row.card-header {
  font-size: 16;
  font-weight: 700;
  margin-bottom: 20px;
}
.inner-column {
  display: flex;
  flex-direction: column;
}
.inner-column.two-thirds {
  flex-basis: 66%;
}
.inner-column.one-third {
  flex-basis: 33%;
}
.inner-column.one-half {
  flex-basis: 50%;
}
.inner-column.center-content {
  justify-content: center;
}

.graph-header {
  display: flex;
  align-items: center;
}

.buy-holofuel {
  background: #735CFE;
  color: #FFFFFF;
  border: 1px solid #735CFE;
  margin-left: 30px;
  width: 124px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  font-size: 12px;
}

.mock-graph {
  margin-left: 12px;
  margin-top: 36px;
}

.hf-account-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 5fr;
  grid-template-columns: 1fr 3fr;
  margin-top: 12px;
}
.hf-account-label {
  font-size: 14px;
  font-weight: 600;
}

.hf-account-data {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.identicon-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hf-address {
  margin: 20px;
}

.hf-link-container {
  display: flex;
  cursor: pointer;
  padding-top: 12px;
}

.hf-link-container span {
  color: var(--content-color);
  font-size: 14px;
  font-weight: 600;
  margin-left: 6px;
  text-decoration: underline;
}

.grayed-out {
  opacity: 0.4;
}

.panel-link {
  width: 80%;
  margin-bottom: 20px;
}
@media screen and (max-width: 1300px) {
  .panel-link {
    width: 100%;
  }

  .card.taller {
    height: 450px;
  }
}
</style>
