<template>
  <Modal :handleClose="handleClose" modalClass='published-happ-modal'>
    <CircledCheckIcon />
    <div class='content'>
      <p>
        This hApp has been published!
      </p>
      <p v-if="hostedUrl">
        URL of hosted UI:
        <br />
        <a :href="hostedUrl" class='hosted-url' target="_blank">{{ hostedUrl }}</a>
      </p>
      <p>
        Please note it may take a few hours to complete the publication of your hApp.
      </p>
    </div>
    <div class='buttons'>
      <Button class='edit' color='secondary' @click="goToEdit">Edit hApp</Button>
      <Button class='close' color='primary' @click="goToDashboard">Close</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'pinia'
import { useUiStore } from 'src/stores'
import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button'
import CircledCheckIcon from '@uicommon/components/icons/CircledCheckIcon'
import { ensureProtocol } from 'src/utils'

export default {
  name: 'HappPublishedModal',
  components: {
    Modal,
    Button,
    CircledCheckIcon  
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  methods: {
    goToEdit () {
      this.$router.push({ path: `/happ/${this.lastPublishedHapp.id}`})
      this.handleClose()
    },
    goToDashboard () {
      this.handleClose()
      this.$router.push('/dashboard')
    }
  },
  computed: {
    ...mapState(useUiStore, ['lastPublishedHapp']),
    hostedUrl () {
      if (this.lastPublishedHapp?.hosted_urls?.length > 0) {
        return ensureProtocol(this.lastPublishedHapp.hosted_urls[0])
      } else {
        return null
      }
    }
  }
}
</script>

<style>
.published-happ-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
}
.hosted-url {
  font-weight: 400;
  color: #313C59;
  text-decoration: none;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
</style>
