import { defineStore } from 'pinia'
import { kDontShowGoToHoloFuelModalAgainLSKey } from 'src/utils'

const useUiStore = defineStore('ui', {
  state: () => ({
    flashMessage: null,
    draftSavedModalVisible: false,
    happPublishedModalVisible: false,
    goToHoloFuelModalVisible: false,
    confirmPauseModalVisible: false,
    happPausedModalVisible: false,
    happUnpausedModalVisible: false,
    confirmStopModalVisible: false,
    happStoppedModalVisible: false,
    nameSetterModalVisible: false,
    identityModalVisible: false,
    lastPublishedHapp: null,
    happToPauseId: null,
    happToStopId: null,
    hcErrorMessage: null,
    dontDisplayConfirmLinkToHoloFuelMessageAgain:
      (localStorage.getItem(kDontShowGoToHoloFuelModalAgainLSKey) === 'true'),
    publisherHoloFuelUrl: process.env.VUE_APP_PUBLISHER_HOLOFUEL_URL
      ? process.env.VUE_APP_PUBLISHER_HOLOFUEL_URL : "http://localhost:8081",
    springboardUrl: process.env.VUE_APP_SPRINGBOARD_URL
      ? process.env.VUE_APP_SPRINGBOARD_URL : "https://springboard.dev.holotest.net"
  }),
  actions: {
    openHappPublishedModal (happ) {
      this.happPublishedModalVisible = true
      this.lastPublishedHapp = happ
    },
    closeHappPublishedModal () {
      this.happPublishedModalVisible = false
      this.setLastPublishedHapp = null
    },
    openGoToHoloFuelModal () {
      this.goToHoloFuelModalVisible = true
    },
    closeGoToHoloFuelModal () {
      this.goToHoloFuelModalVisible = false
    },       
    openConfirmPauseModal (happToPauseId) {
      this.confirmPauseModalVisible = true
      this.happToPauseId = happToPauseId
    },
    closeConfirmPauseModal () {
      this.confirmPauseModalVisible = false
      this.happToPauseId = null
    },
    openConfirmStopModal (happToStopId) {
      this.confirmStopModalVisible = true
      this.happToStopId = happToStopId
    },
    closeConfirmStopModal () {
      this.confirmStopModalVisible = false
      this.happToStopId = null
    },
    openNameSetterModal () {
      this.nameSetterModalVisible = true
    },
    closeNameSetterModal () {
      this.nameSetterModalVisible = false
    },
    openIdentityModal () {
      this.identityModalVisible = true
    },
    closeIdentityModal () {
      this.identityModalVisible = false
    }
  }
})

export default useUiStore
