import { defineStore } from 'pinia'
import useIsLoadingStore from '@uicommon/stores/useIsLoadingStore'

const HHA_ROLE_ID =  'core-app'

const makeUseHhaStore = ({ useHoloStore }) => {
  
  const callZome = args =>
    useHoloStore().callZome({
      ...args,
      role_name: HHA_ROLE_ID,
      zome_name: 'hha'
    })

  const callZomeWithArgs = async (fn_name, payload) => {
    const callZomeArgs = {
      fn_name,
      payload: payload,
    }

    return await callZome(callZomeArgs)
  }

  return defineStore('hha', {
    state: () => ({
      happs: [],
    }),
    getters: {
      getMyHappsIsLoading: state => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'get_my_happs' }),
      pauseIsLoading: state => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'publisher_pause_happ' }),
      unpauseIsLoading: state => 
        useIsLoadingStore().isLoading({ zome_name: 'hha', fn_name: 'publisher_unpause_happ' }),
      getHappName: state => id => state.happs.find(happ => happ.id === id)?.name
    },
    actions: {
      happ(id) {
         return (!this.happs) ? null : this.happs.find((happ) => happ.id === id)
      },      
      async loadHapps() {
        this.happs = await callZomeWithArgs('get_my_happs', null)
      },
      async createDraft(payload) {
        const result = await callZomeWithArgs('create_draft', payload)

        this.happs = this.happs.concat(result)

        return result
      },
      async updateDraft(payload) {
        const updatedDraft = await callZomeWithArgs('update_draft', payload)

        this.happs = this.happs.map((happ) =>
          happ.id === payload.id ? updatedDraft : happ
        )

        return updatedDraft
      },
      async publishHapp(draftId) {
        const publishedHapp = await callZomeWithArgs('publish_happ', draftId)

        this.happs = this.happs.map((happ) =>
          happ.id === draftId ? publishedHapp : happ
        )

        return publishedHapp
      },
      async createAndPublishDraft(payload) {
        const draft = await this.createDraft(payload)
        return this.publishHapp(draft.id)
      },
      async updateHapp(payload) {
        const updatedHapp = await callZomeWithArgs('update_happ', payload)

        this.happs = this.happs.map((happ) =>
          happ.id === payload.id ? updatedHapp : happ
        )        

        return updatedHapp          
      },
      async pauseHapp(happId) {
        await callZomeWithArgs('publisher_pause_happ', happId)

        this.happs = this.happs.map((happ) =>
          happ.id === happId ? {...happ, is_paused: true } : happ
        )
      },
      async unpauseHapp(happId) {
        await callZomeWithArgs('publisher_unpause_happ', happId)

        this.happs = this.happs.map((happ) =>
          happ.id === happId ? {...happ, is_paused: false } : happ
        )
      },
      async removeHapp(happId) {
        await callZomeWithArgs('publisher_remove_happ', happId)

        this.happs = this.happs.filter(happ => happ.id !== happId)
      },
    }    
  })
}

export default makeUseHhaStore
