<template>
  <Icon :fill="color" class='icon'>
    <path :fill-opacity="fillOpacity" d="M16.2107 0H10.7744C10.3385 0 9.98521 0.353263 9.98521 0.789155C9.98521 1.22505 10.3385 1.57831 10.7744 1.57831H14.3932L6.64874 9.3228C6.34039 9.63095 6.34039 10.1307 6.64874 10.4389C6.80282 10.5929 7.00485 10.6701 7.20668 10.6701C7.40852 10.6701 7.61055 10.5929 7.76463 10.4389L15.4216 2.78175V6.2253C15.4216 6.6612 15.7748 7.01446 16.2107 7.01446C16.6466 7.01446 16.9999 6.6612 16.9999 6.2253V0.789155C16.9999 0.353263 16.6466 0 16.2107 0Z" />
    <path :fill-opacity="fillOpacity" d="M13.4812 7.94761C13.0453 7.94761 12.6921 8.30087 12.6921 8.73677V14.9628C12.6921 15.2481 12.4512 15.4889 12.166 15.4889H2.10441C1.81919 15.4889 1.57831 15.2481 1.57831 14.9628V4.90129C1.57831 4.61606 1.81919 4.37519 2.10441 4.37519H8.35246C8.78835 4.37519 9.14162 4.02192 9.14162 3.58603C9.14162 3.15014 8.78835 2.79688 8.35246 2.79688H2.10441C0.943992 2.79688 0 3.74087 0 4.90129V14.9628C0 16.1233 0.943992 17.0672 2.10441 17.0672H12.166C13.3264 17.0672 14.2704 16.1233 14.2704 14.9628V8.73677C14.2704 8.30106 13.9171 7.94761 13.4812 7.94761Z" />
  </Icon>
</template>
<script>


import Icon from './Icon.vue'

export default {
  name: 'ArrowInSquare',
  components: {
    Icon
  },
  props: {
    disabled: {type: Boolean, default: false },
    color: {
      type: String,
      default: "#313C59"
    }
  },
  computed: {
    fillOpacity () {
      return this.disabled ? '0.18' : '1'
    }
  }  
}
</script>

<style scoped>
.icon {
  width: 26px;
  height: 26px;
}
</style>  