<template>
  <div class='message'>{{ message }}  <Button v-if="showLogout" color='primary' @click="handleLogout">Logout</Button></div>

</template>

<script>
import Button from '@uicommon/components/Button.vue'
import { mapState, mapActions } from 'pinia'
import { useHoloStore, resetHhaStore } from 'src/stores'


export default {
  name: 'Login',
  components: {
    Button
  },

  computed: {
    ...mapState(useHoloStore, ['error']),
    showLogout () {
      return !!this.error
    },
    message () {
      if (this.error) {
        return this.error
      } else {
        return 'Connecting to Holo network...'
      }
    },
    handleLogout () {
      resetHhaStore()
      this.signOut()
    }
  },
  methods: {
    ...mapActions(useHoloStore, ['signOut']),
  },
}

</script>

<style scoped>
.message {
  margin: auto;
}
</style>
