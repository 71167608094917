<template>
  <PrimaryLayout :breadcrumbs="breadcrumbs">
    <AvailableBalance :amount="fullBalance" />
    <div class="controls">
      <BaseSearchInput
        :value="filterValue"
        @update:value="handleFilterChange"
      />
    </div>

    <BaseTable
      v-slot="{ items }"
      :is-loading="invoicesAreLoading"
      :headers="headers"
      initial-sort-by="date"
      :items="invoices"
      :empty-message-translation-key="'invoices.empty_state'"
    >
      <InvoicesTableRow
        v-for="item in items"
        :key="item.id"
        :invoice="item"
      /> 
    </BaseTable>
  </PrimaryLayout>
</template>

<script>
import { mapState } from 'pinia'
import BaseSearchInput from '@uicommon/components/BaseSearchInput'
import BaseTable from '@uicommon/components/BaseTable'
import PrimaryLayout from 'components/PrimaryLayout'
import InvoicesTableRow from 'components/InvoicesTableRow'
import AvailableBalance from 'components/AvailableBalance'
import { useHolofuelStore } from 'src/stores'

const headers = [
  {
    key: 'happName',
    label: 'hApp',
    isVisibleOnMobile: true,
    isSortable: true
  },
  {
    key: 'date',
    label: 'Date Received',
    isVisibleOnMobile: false,
    isSortable: true
  },
  {
    key: 'id',
    label: 'Transaction Hash',
    isVisibleOnMobile: false,
    isSortable: false
  },
  {
    key: 'amount',
    label: 'Amount',
    isVisibleOnMobile: true,
    isSortable: true,
    align: 'end'
  },
  {
    key: 'status',
    label: 'Payment Status',
    isVisibleOnMobile: true,
    isSortable: true
  }
]

export default {
  name: 'BillingAndPaymentInvoices',
  components: {
    PrimaryLayout,
    BaseSearchInput,
    BaseTable,
    InvoicesTableRow,
    AvailableBalance
  },
  data () {
    return {
      breadcrumbs: [
        {
          label: 'Billing & Payments',
          path: '/billing-and-payments'
        },
        {
          label: 'Invoices'
        }
      ],
      headers,
      filterValue: ''
    }
  },
  methods: {
    handleFilterChange (val) {
      this.filterValue = val
    }
  },
  computed: {
    ...mapState(useHolofuelStore, ['actionableHostingInvoices', 'completedHostingInvoices', 'invoicesAreLoading', 'fullBalance']),
    invoices () {
      return this.actionableHostingInvoices.concat(this.completedHostingInvoices).filter(transaction => {
        if (!this.filterValue) return true
        
        const downcaseFilterValue = this.filterValue.toLowerCase()

        return transaction.id.toLowerCase().includes(downcaseFilterValue)
        || transaction.date.toLowerCase().includes(downcaseFilterValue)
        || transaction.happName.toLowerCase().includes(downcaseFilterValue)
        || transaction.status.toLowerCase().includes(downcaseFilterValue)
        || transaction.amount.includes(downcaseFilterValue)
        || transaction.formattedAmount.includes(downcaseFilterValue)      
      })
    }
  }
}

</script>

<style scoped>
.controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}
</style>
