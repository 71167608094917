<template>
  <router-view />
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useHolofuelStore, useHoloStore, useHhaStore } from 'src/stores'

const AUTOPAY_INTERVAL = 60_000

export default {
  name: 'App',
  data () {
    return {
      autoPayIntervalId: null
    }
  },
  async mounted() {
    try {
      await this.initialize()
      if (this.isAnonymous) {
        this.openAuthForm()
      }
    } catch (e) {
      console.error('Holo store init error', e)
    }
  },
  methods: {
    ...mapActions(useHolofuelStore, ['loadMyNickname','loadLedger', 'loadAndPayInvoices']),
    ...mapActions(useHhaStore, ['loadHapps']),
    ...mapActions(useHoloStore, ['initialize', 'signIn', 'signUp', 'signOut']),

    openAuthForm () {
      if (this.$route.path == '/signup') {
        this.signUp()
      } else {
        this.signIn()
      }

    }
  },
  computed: {
    ...mapWritableState(useHoloStore, ['isAuthFormOpen']),
    ...mapState(useHoloStore, ['isAnonymous', 'isLoggedIn'])
  },
  watch: {
    async isLoggedIn(is_logged_in) {
      if (is_logged_in) {
        this.isAuthFormOpen = false
        await this.loadHapps()
        this.loadMyNickname()
        this.loadLedger()
        this.$router.push(this.$route.params.nextUrl || '/dashboard')
        this.loadAndPayInvoices()
        this.autoPayIntervalId = setInterval(() => this.loadAndPayInvoices(), AUTOPAY_INTERVAL)
      } else {
        clearInterval(this.autoPayIntervalId)
        this.autoPayIntervalId = null
      }
    },
    isAnonymous(is_anonymous) {
      if (is_anonymous && !this.isAuthFormOpen) {
        this.openAuthForm()
      }
    },
  },
}
</script>

<style>
@import '../ui-common-library/artifacts/styles/common.css';
@import './common.css';

html {
  overflow-y: scroll;
}
body,
html {
  height: 100%;
}
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafcfe;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
