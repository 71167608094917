
<template>
  <Icon class='icon' viewBox="0 0 14 14">
    <path d="M7.028 3.178c-1.12 0-2.044.91-2.044 2.044a.517.517 0 101.036 0c0-.546.448-.994.994-.994.574 0 .994.406.994.952v.042c0 .308-.084 1.036-1.078 1.19a.527.527 0 00-.448.518v1.414a.517.517 0 101.036 0V7.35c1.316-.406 1.526-1.54 1.526-2.17.028-1.12-.896-2.002-2.016-2.002zM7.028 9.492a.672.672 0 00-.672.672c0 .364.308.672.672.672a.681.681 0 00.672-.672.681.681 0 00-.672-.672z" fill="#735CFE"/>
    <path d="M7.028.112C3.262.112.196 3.178.196 6.944s3.066 6.832 6.832 6.832 6.832-3.066 6.832-6.832S10.794.112 7.028.112zm0 12.628a5.808 5.808 0 01-5.796-5.796 5.808 5.808 0 015.796-5.796 5.808 5.808 0 015.796 5.796c-.014 3.192-2.604 5.796-5.796 5.796z" fill="#735CFE"/>
  </Icon>
</template>

<script>

import Icon from './Icon.vue'

export default {
  name: 'SmallQuestionIconIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 14px;
  height: 14px;
}
</style>
