import makeUseHolofuelStore from "./useHolofuelStore"
import makeUseHoloStore from "@uicommon/stores/useHoloStore"
import useUiStoreRaw from "./useUiStore"
import makeUseHhaStore from "./useHhaStore"
import MockWebSdk from '../../__mocks__/@holo-host/web-sdk'

export const useUiStore = useUiStoreRaw

const CHAPERONE_URL = process.env.VUE_APP_CHAPERONE_SERVER_URL
  ? process.env.VUE_APP_CHAPERONE_SERVER_URL
  : "http://localhost:24274"

let membraneProofServer
if (process.env.VUE_APP_MEMPROOF_SERVER_URL) {
  membraneProofServer = {
    url: process.env.VUE_APP_MEMPROOF_SERVER_URL,
    payload: {
      role: 'publisher'
    }
  }
}

export const useHoloStore = makeUseHoloStore({
  connectionArgs: {
    chaperoneUrl: CHAPERONE_URL,
    authFormCustomization: {
      publisherName: 'Holo',
      appName: 'Publisher Portal',
      requireRegistrationCode: true,
      anonymousAllowed: false,
      membraneProofServer
    }
  },
  MockWebSdk: process.env.VUE_APP_MOCK_WEB_SDK ? MockWebSdk : null,
})

export const useHolofuelStore = makeUseHolofuelStore({ 
  useHoloStore
})

export const useHhaStore = makeUseHhaStore({
  useHoloStore
})

export const resetHhaStore = () => useHhaStore().$reset()