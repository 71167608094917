<template>
  <Modal :handleClose="handleClose" modalClass='draft-saved-modal' :showEx="false">
    <CircledPauseIcon />
    <div class='title'>hApp paused!</div>
  </Modal>
</template>

<script>
import Modal from '@uicommon/components/Modal'
import CircledPauseIcon from '@uicommon/components/icons/CircledPauseIcon'

export default {
  name: 'HappPausedModal',
  components: {
    Modal,
    CircledPauseIcon
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  }
}
</script>

<style>
.draft-saved-modal.modal {
  min-width: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.title {
  margin-top: 30px;
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
}
</style>
