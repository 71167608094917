<template>
  <Modal :handleClose="handleClose" modalClass='happ-stopped-modal'>
    <CircledCheckIcon />
    <div class='content'>
      <p>
        This hApp has been removed from Holo hosting.
      </p>
      <p>
        Please note it may take a few days to complete the removal process.
      </p>
    </div>
    <div class='buttons'>
      <Button class='close' color='primary' @click="handleClose">Close</Button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button'
import CircledCheckIcon from '@uicommon/components/icons/CircledCheckIcon'

export default {
  name: 'HappStoppedModal',
  components: {
    Modal,
    Button,
    CircledCheckIcon  
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  }
}
</script>

<style>
.happ-stopped-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
}
.confirmation {
  margin: 30px 0;
  font-weight: bold;
}
.temp-domain {
  font-weight: 400;
  color: #313C59;
  text-decoration: none;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
</style>
