<template>
  <Modal :handleClose="handleClose" modalClass='confirm-stop-modal'>
    <CircledExclamationIcon />
    <div class='content'>
      <p>
        This action will permanently remove the hApp from Holo hosting and delete all hosted data, including web user sourcechains, from all host devices.
        All hosted user data will also be permanently lost. Outstanding payments will be settled automatically.  
      </p>
      <p>
        If you would like to temporarily pause hosting for this hApp instead, please use the <a class='pause-happ' @click="goToPauseHapp">Pause hApp</a> option.
      </p>
    </div>
    <div class='footer'>
      <div class='confirmation-label'>Type REMOVE to confirm</div>
      <input class='confirmation-check' v-model="confirmationText" />
      <div class='buttons'>      
        <Button class='edit' color='secondary' @click="handleClose">Cancel</Button>
        <Button class='close' :color="confirmButtonColor" :disabled="confirmDisabled" @click="confirm">Remove hApp</Button>
      </div>
    </div>  
  </Modal>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore, useHhaStore } from 'src/stores'

import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button'
import CircledExclamationIcon from '@uicommon/components/icons/CircledExclamationIcon'

export default {
  name: 'ConfirmStopModal',
  components: {
    Modal,
    Button,
    CircledExclamationIcon  
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      confirmationText: ''
    }
  },
  methods: {
    ...mapActions(useHhaStore, ['removeHapp']),
    ...mapActions(useUiStore, ['openConfirmPauseModal', 'closeConfirmStopModal']),

    goToPauseHapp () {
      const happId = this.happToStopId
      this.openConfirmPauseModal(happId)
      this.closeConfirmStopModal()
    },
    confirm () {
      console.log('confirming', this.confirmationText)
      if (this.confirmDisabled) return

      try {
        this.removeHapp(this.happToStopId)
        this.$router.push('/dashboard')
        this.handleClose()
        this.happStoppedModalVisible = true
      } catch (e) {
        this.hcErrorMessage = e.message
        this.handleClose()
      }
    },
  },
  computed: {
    ...mapState(useUiStore, ['happToStopId']),
    ...mapWritableState(useUiStore, ['happStoppedModalVisible', 'hcErrorMessage']),
    confirmDisabled () {
      return this.confirmationText !== 'REMOVE'
    },
    confirmButtonColor () {
      return this.confirmDisabled ? 'disabled' : 'primary'
    }
  },
  
}
</script>

<style>
.confirm-stop-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
  margin-top: 20px;
}
.footer {
  margin-top: 50px;
}
.confirmation {
  margin: 30px 0;
}
.confirmation-label {
  font-weight: bold;
}
.confirmation-check {
  border: 2px solid #735CFE;
  border-radius: 2px;
  width: 100%;
  height: 24px;
  margin: 5px 0 20px 0;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.pause-happ {
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
}
</style>
