
<template>
  <Icon class='icon' viewBox="0 0 52 52">
    <path d="M26 0a26 26 0 100 52 26 26 0 000-52zm13 27.86H27.86V39a1.86 1.86 0 11-3.72 0V27.86H13a1.86 1.86 0 110-3.72h11.14V13a1.86 1.86 0 113.72 0v11.14H39a1.86 1.86 0 110 3.72z" fill="#735CFE"/>
  </Icon>
</template>

<script>

import Icon from './Icon.vue'

export default {
  name: 'PlusInCircleIcon',
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  width: 52px;
  height: 52px;
}
</style>
