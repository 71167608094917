// Returns a best-effort approximation of how much resources a happ would use per month
export const statsPerMonth = (uniqueUsers, dailyUsers, cpuPrice, storagePrice, bandwidthPrice) => {
    // Static size of a new initialized source chain
    const STANDARD_SIZE_OF_INITIAL_SOURCE_CHAIN = 500 // in bytes
    // Average Number of zomeCalls made per min by the UI
    const zomeCallPerMin = 1 // randomInteger(1, 20)
    // Average Time in sec that it takes to complete one zomeCall
    const secPerZomeCall = 50 // randomInteger(10, 80)
    // Average Payload size for a GET zomeCall
    const getZomeCallPayloadSizePerZomeCall = 100 // randomInteger(80, 110)
    // Average Payload size for a POST(create) zomeCall
    const postZomeCallPayloadSizePerZomeCall = 100 // randomInteger(80, 110)
    // Average time in mins spent by a user is on the website
    const minPerUse = 120 // randomInteger(30, 200)

    // Number of users per month
    const usePerMonth = dailyUsers * 30
    // Average number of zomeCalls made per month
    const noOfZomeCallsPerMonth = minPerUse * usePerMonth * zomeCallPerMin

    const cpuPerMonth = (noOfZomeCallsPerMonth * secPerZomeCall) / 60 // mins

    const storagePerMonth = (
        (STANDARD_SIZE_OF_INITIAL_SOURCE_CHAIN * uniqueUsers) +
        (noOfZomeCallsPerMonth * 0.25 * postZomeCallPayloadSizePerZomeCall)
        ) / (1024 * 1024 * 1024)

    const bandwidthPerMonth = (
        (noOfZomeCallsPerMonth * 0.75 * getZomeCallPayloadSizePerZomeCall) +
        (noOfZomeCallsPerMonth * 0.25 * postZomeCallPayloadSizePerZomeCall)
        ) / (1024 * 1024 * 1024)

    const totalCostPerMonth = ((cpuPerMonth / 60) * cpuPrice) +
        (storagePerMonth * storagePrice) +
        (bandwidthPerMonth * bandwidthPrice)

    return [
        cpuPerMonth,
        storagePerMonth.toFixed(5),
        bandwidthPerMonth.toFixed(5),
        totalCostPerMonth
    ]
}
