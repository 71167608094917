<template>
  <Modal :handleClose="handleClose" modalClass='confirm-pause-modal'>
    <CircledExclamationIcon />
    <div class='content'>
      <p>
        Please note that pausing this hApp will make it unavailable to people on the web, and it may take up to 24 hours to become available again once unpaused. 
        You will still be responsible for storage services provided by hosts.
      </p>
      <p class='confirmation'>
        Are you sure you want to pause this hApp?
      </p>
    </div>
    <div class='buttons'>
      <Button class='edit' color='secondary' @click="handleClose">Cancel</Button>
      <Button class='close' color='primary' @click="confirm">Yes, pause hApp</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore, useHhaStore } from 'src/stores'

import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button.vue'
import CircledExclamationIcon from '@uicommon/components/icons/CircledExclamationIcon'

export default {
  name: 'ConfirmPauseModal',
  components: {
    Modal,
    Button,
    CircledExclamationIcon  
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  methods: {
    ...mapActions(useHhaStore, ['pauseHapp']),
    confirm () {
      try {
        this.pauseHapp(this.happToPauseId)
        this.handleClose()
        this.happPausedModalVisible = true
        setTimeout(() => {
          this.happPausedModalVisible = false
          this.$router.push('/dashboard')
        }, 3_000)
      } catch (e) {
        this.hcErrorMessage = e.message
        this.handleClose()
      }
    },
  },
  computed: {
    ...mapState(useUiStore, ['happToPauseId']),
    ...mapWritableState(useUiStore, ['happPausedModalVisible', 'hcErrorMessage']),
  }
}
</script>

<style>
.confirm-pause-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
}
.confirmation {
  margin: 30px 0;
  font-weight: bold;
}
.temp-domain {
  font-weight: 400;
  color: #313C59;
  text-decoration: none;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
</style>
