import { createApp } from 'vue'
import App from './App.vue'
import clickOutside from '@uicommondirectives/clickOutside'
import { createI18n } from 'vue-i18n'
import messages from './locales'
import { createPinia } from 'pinia'
import router from './router'

const pinia = createPinia()

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  legacy: false
})

createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .directive('click-outside', clickOutside)
  .mount('#app')
